  @font-face {
  	font-family: "GothamPro";
  	src: url("../fonts/GothamPro.woff2") format("woff2"),
  		url("../fonts/GothamPro.woff") format("woff");
  	font-weight: 400;
  	font-style: normal;
  }

  @font-face {
  	font-family: "GothamPro";
  	src: url("../fonts/GothamPro-Medium.woff2") format("woff2"),
  		url("../fonts/GothamPro-Medium.woff") format("woff");
  	font-weight: 500;
  	font-style: medium;
  }