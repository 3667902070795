.omron {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 41.5%,
    rgba(31, 31, 31, 1) 41%
  );
  margin-top: -1px;
}

.omron__mobile-text {
  display: none;
}

.omron__wrapper {
  position: relative;

  padding-top: 60px;
  padding-bottom: 60px;

  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 41.5%,
    rgba(31, 31, 31, 1) 41%
  );

  &::before {
    position: absolute;
    content: "";
    width: 1px;
    height: calc(100% + 94px);
    background-color: #444444;
    top: -94px;
    right: 100px;
  }
}

.omron__image {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.omron__arrow {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
}

.omron__title {
  font-family: inherit;
  font-size: 48px;
  line-height: 52px;
  color: #ffffff;
  margin-top: 60px;
  max-width: 380px;
  font-weight: 500;
}

.omron__subtitle {
  font-family: inherit;
  font-size: 15px;
  line-height: 28px;
  color: #898989;
  margin-top: 45px;
  max-width: 380px;

  & span {
    color: #ffffff;
  }
}

.omron__slide {
  padding: 40px 0;

  & img {
    max-width: 425px;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    object-fit: cover;
    opacity: 0 !important;
    transition: 0.5s ease-in-out;
  }

  &.swiper-slide-active {
    & img {
      opacity: 1 !important;
    }
  }
}

.omron__slide-wrapper {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background: linear-gradient(186.48deg, #838b8e 5.4%, #697073 94.9%);
    border-radius: 456px 456px 40px 40px;
    width: 367px;
    height: 470px;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/shadow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 363px;
    height: 415px;
    top: 50%;
    left: 59%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.omron__swiper {
  max-width: 500px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.omron__wrapper {
  display: flex;
}

.omron__wrap {
  position: relative;

  &--1 {
    width: 41.5%;
    margin-right: 117px;
  }

  &--2 {
    width: 58.5%;

    & .omron__wrap-decor {
      width: 437px;
      height: 437px;
      border: 1px solid #3d3d3e;
      border-radius: 50%;

      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);

      & span {
        position: relative;
        font-family: inherit;
        font-size: 14px;
        line-height: 28px;
        color: #ffffff;

        &::after {
          position: absolute;
          content: "";
          top: 10px;
          right: -33px;
          width: 6px;
          height: 6px;
          background: #61b4e1;
          border-radius: 50%;
        }

        &::before {
          position: absolute;
          content: "";
          top: 3px;
          right: -40px;
          width: 20px;
          height: 20px;
          background: #fff;
          opacity: 0.3;
          border-radius: 50%;
        }

        &:nth-child(1) {
          position: absolute;
          top: 90px;
          left: -76px;
        }

        &:nth-child(2) {
          position: absolute;
          top: 169px;
          left: -127px;
        }

        &:nth-child(3) {
          position: absolute;
          top: 252px;
          left: -113px;
        }
      }
    }
  }
}

.omron__logo-list {
  display: flex;
  align-items: center;
  margin-top: 108px;

  & li {
    display: flex;
    align-items: center;
    margin-right: 10px;

    & span {
      font-family: inherit;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      height: max-content;
      align-self: flex-start;
      margin-left: 5px;
      margin-top: -5px;
    }
  }
}

.omron__link {
  margin-top: 85px;
}

.omron__wrap-social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: max-content;
  position: absolute;
  top: 53%;
  right: -10px;
  transform: translateY(-50%);
  z-index: 1;

  & li {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        & path {
          fill: #3e7bbf;
        }
      }
    }
  }
}

.omron__pagination {
  & .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
    width: 5px;
    height: 5px;
    margin: 0 10px !important;
  }

  & .swiper-pagination-bullet-active {
    background: #61b4e1;
  }
}

.omron__video {
  position: absolute;

  top: 68%;
  right: -186px;

  transform: translateY(-50%);
  width: 76px;
  height: 76px;
  display: inline-block;
  background: transparent;
  border: 3px solid #f7d06c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  & svg {
    margin-left: 3px;
    z-index: 1;
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: 0.3s ease;
    border-color: #f7d06c;
  }

  &:hover {
    border-color: transparent;

    &::after {
      background: #f7d06c;
      transform: scale(1.75);
    }

    & svg path {
      fill: #181818;
    }
  }
}

@media (min-width: 0px) and (max-width: 1365px) {
  .omron__wrapper {
    flex-direction: column;
    padding-bottom: 155px;
    background: linear-gradient(180deg, #181818 50%, #1f1f1f 50%);

    &::before {
      display: none;
    }
  }

  .omron__wrap-social {
    display: none;
  }

  .omron__wrap--1 {
    width: 100%;
    margin-right: 0;
  }

  .omron__title {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    margin-top: 26px;
  }

  .omron__subtitle {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 36px;
  }

  .omron__wrap-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 44px;
    padding-bottom: 130px;

    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  .omron__link {
    margin-top: 0;
  }

  .omron__logo-list {
    margin-top: 0;
  }

  .omron__wrap--2 {
    width: 100%;
    margin-top: 105px;
  }

  .omron__video {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    top: unset;
  }

  .omron__image {
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .omron__pagination {
    bottom: -30px !important;
  }

  .omron__arrow {
    bottom: 55px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .omron__wrapper {
    padding-top: 18px;
    background: linear-gradient(180deg, #181818 43%, #1f1f1f 43%);
    padding-bottom: 146px;
  }

  .omron__title {
    font-size: 24px;
    line-height: 32px;
  }

  .omron__subtitle {
    font-size: 15px;
    line-height: 28px;
    margin-top: 34px;
  }

  .omron__wrap-content {
    flex-direction: column;
  }

  .omron__logo-list {
    margin-top: 42px;
  }

  .omron__wrap--2 .omron__wrap-decor {
    width: 285px;
    height: 285px;
    top: 0;
  }

  .omron__swiper {
    max-width: 275px;

    & .swiper-wrapper {
      margin-top: -37px;
    }
  }

  .omron__slide-wrapper {
    padding-top: 45px;

    &::after {
      width: 239px;
      height: 306px;
      top: 50%;
    }
  }

  .omron__slide img {
    max-width: 275px;
  }

  .omron__wrap--2 {
    margin-top: 268px;
  }

  .omron__mobile-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -130%);

    & span {
      font-family: inherit;
      font-size: 14px;
      line-height: 28px;
      color: #ffffff;
      margin-top: 6px;
    }
  }

  .omron__wrap-decor {
    & span {
      text-indent: -999em;
      font-size: 0;
      transform: scale(0.6);
    }
  }

  .omron__wrap--2 .omron__wrap-decor span:nth-child(1) {
    top: 4px;
    left: 54px;
  }

  .omron__wrap--2 .omron__wrap-decor span:nth-child(2) {
    top: -14px;
    left: 121px;
  }

  .omron__wrap--2 .omron__wrap-decor span:nth-child(3) {
    top: 3px;
    left: 189px;
  }

  .omron__image {
    top: 43%;
  }

  .omron__pagination {
    bottom: 10px !important;
  }
}

@media (min-width: 2560px) {
  .omron__wrapper {
    padding-bottom: 150px;
  }
}

// @media(min-width: 1366px) {
// 	.omron__wrapper {
// 		height: 70vh;
// 	}
// }
