.app {
  position: relative;
  overflow: hidden;
  background-color: #181818;
  padding-top: 187px;
  // margin-top: 12px;
  padding-bottom: 80px;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 80px;
    background-color: #fff;
    left: 0;
    bottom: 0;
  }
}

// .app__wrapper {}

.app__title {
  color: #ffffff;
  max-width: 603px;
}

.app__subtitle {
  font-family: inherit;
  font-size: 15px;
  line-height: 24px;
  color: #898989;
  margin-top: 82px;
  max-width: 560px;

  & span {
    color: #fff;
  }
}

.app__wrap {
  position: relative;
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 57.5%,
    rgba(31, 31, 31, 1) 57.5%
  );

  & .app__info {
    width: 57.5%;
  }

  & .app__subinfo {
    width: 42.5%;

    & p {
      font-family: inherit;
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      font-weight: 500;
      margin-top: 413px;
      margin-left: -50px;
      max-width: 500px;
    }
  }

  & .app__wrap-decor {
    position: absolute;
    top: 0;
    right: 0;
    background: #1f1f1f;
    width: 100vw;
    height: 100%;
    transform: translateX(100%);
  }

  &:nth-child(2) {
    padding-top: 110px;
    margin-top: -208px;

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 200vw;
      background-color: #444444;
      left: -100vw;
      top: 0;
    }

    & .app__subinfo {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background-image: url("../img/app-app.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: 387px;
        height: 132px;
        top: 50%;
        left: 50%;
        transform: translate(-37%, -90%);
        pointer-events: none;
      }

      &::after {
        position: absolute;
        content: "";
        background-image: url("../img/app-arr.svg");
        background-repeat: no-repeat;
        background-position: center center;
        width: 35px;
        height: 32px;
        top: 205px;
        left: 112px;
        pointer-events: none;
      }
    }
  }
}

.app__possibilities {
  display: flex;
  align-items: center;
  background: #1f1f1f;
  padding: 61px 66px;
  max-width: 467px;
  margin-top: 100px;
}

.app__decor {
  display: flex;
  align-items: center;
  margin: 0 31px;

  & .point {
    width: 5px;
    height: 5px;
    background: #353535;
    display: block;
    border-radius: 50%;
  }

  & div {
    margin: 0 12px;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.3);

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      width: 6px;
      height: 6px;
      background: #61b4e1;
      border-radius: 50%;
    }
  }
}

.app__img {
  z-index: 1;
  position: relative;

  width: 498px;
  height: 712px;
  object-fit: cover;

  &--doble {
    display: none;
  }
}

.app__omron {
  width: 464px;
  height: 450px;
  object-fit: cover;
  margin-top: 170px;
  transform: translateY(80px);
  z-index: 1;
  position: relative;

  &--doble {
    display: none;
  }
}

.app__subinfo-decor {
  display: none;
}

@media (min-width: 0px) and (max-width: 1365px) {
  .app {
    margin-top: 0;
    padding-top: 116px;
  }

  .app__wrap {
    flex-direction: column;

    &:nth-child(1) {
      background: #181818;
      padding-bottom: 106px;
    }

    &:nth-child(2) {
      background: #181818;
    }
  }

  .app__wrap-decor {
    display: none;
  }

  .app__title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .app__subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 59px;
  }

  .app__wrap .app__info {
    width: 100%;
  }

  .app__possibilities {
    margin-left: auto;
    margin-right: auto;
  }

  .app__img {
    display: none;

    &--doble {
      display: block;
      width: 708px;
      height: 640px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 94px;
    }
  }

  .app__wrap:nth-child(2) {
    margin-top: 0;
  }

  .app__omron {
    display: none;

    &--doble {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 522px;
      object-fit: cover;
      width: 100%;
      margin-top: 150px;
      transform: translateY(80px);
      z-index: 1;
      position: relative;
    }
  }

  .app__wrap .app__subinfo {
    width: 100%;
    background: linear-gradient(90deg, #181818 17.5%, #1f1f1f 17.5%);
  }

  .app__wrap .app__subinfo p {
    margin-top: 276px;
    margin-left: 40px;
  }

  .app__wrap:nth-child(2) .app__subinfo::before {
    top: 159px;
    left: 17.5%;
    transform: translate(32%, 0px);
  }

  .app__wrap:nth-child(2) .app__subinfo::after {
    top: 67px;
    left: 17.5%;
    transform: translate(370%, 0px);
  }

  .app__subinfo-decor {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: #1f1f1f;
    width: 100vw;
    height: 100%;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    pointer-events: none;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .app__title {
    max-width: 100%;
  }

  .app__possibilities {
    width: 333px;
    height: 123px;
    padding: 43px 47px;
    margin-top: 56px;

    & img {
      max-width: 26px;
    }
  }

  .app__decor {
    margin: 0px 22px;

    & div {
      width: 12px;
      height: 12px;
      margin: 0px 8px;

      & span {
        width: 4px;
        height: 4px;
      }
    }
  }

  .app__wrap:nth-child(1) {
    padding-bottom: 71px;
  }

  .app__wrap:nth-child(2) {
    padding-top: 60px;
  }

  .app__subtitle {
    margin-top: 50px;
    max-width: 100%;
  }

  .app__img--doble {
    width: 375px;
    height: 466px;
    object-fit: cover;
  }

  .app__wrap .app__subinfo {
    background: linear-gradient(90deg, #181818 8%, #1f1f1f 8%);
  }

  .app__wrap .app__subinfo p {
    margin-top: 188px;
    margin-left: 0px;
  }

  .app__omron--doble {
    margin-top: 120px;
    transform: translateY(40px);
  }

  .app__wrap:nth-child(2) .app__subinfo::after {
    top: 44px;
    transform: translate(165%, 0px);
  }

  .app__wrap:nth-child(2) .app__subinfo::before {
    width: 206px;
    height: 70px;
    background-size: cover;
    top: 123px;
  }
}
