#map {
	width: 100%;
	height: 648px;
	padding: 0;
	margin: 0;
}

.map__variation {
	font-family: inherit;
	font-size: 18px;
	line-height: 24px;
	color: #13110C;
	font-weight: 500;
}

.map__address {
	font-family: inherit;
	font-size: 15px;
	line-height: 24px;
	color: #898989;
	margin-top: 24px;
}

.map__tels {
	& li {
		& a {
			font-family: inherit;
			font-size: 18px;
			line-height: 26px;
			color: #13110C;
			width: max-content;
			font-weight: 500;
		}
	}
}

.map__schedule {
	font-size: 15px;
	line-height: 24px;
	color: #898989;
	margin-top: 21px;
}

.map__wrapper {
	position: relative;
}

.map__info {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100vw - 200px);
	background: #FFFFFF;
	z-index: 1;
	padding: 0;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 80px 86px;
}

.map__wrap {
	&:nth-child(1) {
		max-width: 286px;
		width: 100%;
	}

	&:nth-child(2) {
		max-width: 286px;
		width: 100%;
		margin-left: 50px;
	}
}

.map__menu-social {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: max-content;
	z-index: 1;

	& li {
		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
		}

		& a {
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				& path {
					fill: #3E7BBF;
				}
			}
		}
	}
}

@media(min-width:0px) and (max-width: 1365px) {
	.map__info {
		width: calc(100vw - 120px);
		padding: 70px 47px;
		align-items: flex-start;
	}

	.map__menu-social {
		display: none;
	}
}

@media(min-width:0px) and (max-width: 1365px) {
	.map__info {
		position: static;
		transform: none;
		padding: 111px 20px;
		text-align: center;
	}

	.map__info {
		flex-direction: column;
	}

	.map__wrap:nth-child(1) {
		max-width: 100%;
	}

	.map__wrap:nth-child(2) {
		max-width: 100%;
		margin-left: 0;
		margin-top: 85px;
	}

	#map {
		height: 550px;
	}
}