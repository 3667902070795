.header {
	background: linear-gradient(90deg, rgba(24, 24, 24, 1) 41%, rgba(31, 31, 31, 1) 41%);
}

.header__wrapper {
	display: flex;
	align-items: center;
	padding-top: 32px;
	background: linear-gradient(90deg, rgba(24, 24, 24, 1) 41.5%, rgba(31, 31, 31, 1) 41%);
}

.header__nav {
	margin-right: 90px;
	margin-left: auto;

	& ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		& li {
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}

			& span {
				display: none;
			}

			& a {
				font-family: inherit;
				font-size: 15px;
				line-height: 62px;
				color: #898989;
				transition: 0.3s ease;

				&:hover {
					color: #fff;
				}
			}
		}
	}
}

.header__menu {
	margin-left: auto;
}

.header__menu-social {
	display: none;
}

.header__btn {
	display: none;
}


@media(min-width: 0px) and (max-width: 1365px) {
	.header__wrapper {
		position: relative;
		background: #181818;
	}

	.header__nav {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
	}

	.header__menu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: #181818;
		height: 100vh;
		z-index: 50;
		padding: 94px 96px 100px 94px;
		overflow-y: auto;

		transform: translateY(-105%);

		transition: 0.5s ease-in-out;


		display: flex;
		flex-direction: column;

		& nav ul {
			flex-direction: column;
			align-items: flex-start;

			& li {
				margin-right: 0;
				display: flex;
				align-items: center;
				padding-bottom: 30px;
				padding-top: 30px;
				border-bottom: 1px solid #444444;
				width: 100%;

				& a {
					font-size: 36px;
					line-height: 36px;
					color: #898989;
					padding-left: 40px;
				}

				& span {
					display: block;
					font-family: inherit;
					font-size: 15px;
					line-height: 18px;
					color: #898989;
					align-self: flex-start;
					min-width: 20px;
				}
			}
		}
	}

	.header__menu--active {
		transform: translateY(0px);
	}

	.header__btn {
		display: block;
		transform: scale(0.6);
		margin-left: auto !important;
		z-index: 50;
	}

	.header__logo {
		position: relative;
		z-index: 55;
	}

	.header__menu-social {
		display: flex;
		justify-content: center;
		// margin-top: 250px;

		& li {
			width: 20px;
			height: 20px;
			margin: 0 16px;

			& a {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media(min-width:0px) and (max-width: 767px) {
	.header__wrapper {
		padding-top: 15px;
	}

	.header__menu {
		padding-left: 35px;
		padding-right: 35px;
	}

	.header__menu nav ul li a {
		font-size: 18px;
		line-height: 18px;
	}
}