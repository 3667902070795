.purchase__contact {
	display: flex;
	flex-direction: column;
	margin-top: 42px;

	& a {
		font-family: inherit;
		font-weight: 500;
		font-size: 36px;
		line-height: 24px;
		color: #13110C;
		width: max-content;
	}

	& span {
		font-family: inherit;
		font-size: 15px;
		line-height: 24px;
		color: #13110C;
		margin-top: 22px;
	}
}

.purchase__info {
	font-family: inherit;
	font-size: 14px;
	line-height: 24px;
	color: #13110C;
}

.purchase__text {
	font-family: inherit;
	font-size: 12px;
	line-height: 22px;
	color: #898989;
	margin-top: 40px;

	&--unactive {
		opacity: 0.3;
	}
}

.purchase__content {
	display: flex;
}

.purchase__wrap {
	width: 50%;
}

.purchase__head {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-left: 70px;
	margin-right: 38px;
	padding-top: 97px;
}

.purchase__rotate {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		background-image: url('../img/purchase-rotate-arr.svg');
		background-repeat: no-repeat;
		background-position: center center;
		width: 35px;
		height: 32px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.purchase__content {
	margin-left: 70px;
	margin-right: 70px;
	margin-top: 29px;
}

.purchase__info-image {
	margin-top: 95px;
}

.purchase__btn-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	justify-content: center;

	& .purchase__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: flex-end;
		width: 50px;
		height: 50px;

		& svg {
			transition: 0.5s ease;
			transform: rotate(0deg);

			&.arr-rotate {
				transform: rotate(180deg);
			}
		}
	}

	& p {
		margin-top: 0;
	}
}

.purchase__toggle-hided {
	display: none;
}

@media(min-width: 0px) and (max-width: 1365px) {
	.purchase__head {
		margin-left: 0;
		margin-right: 0;
	}

	.purchase__content {
		margin-left: 0;
		margin-right: 0;
	}

	.purchase__head {
		overflow: hidden;
		padding-top: 86px;
	}
}

@media(min-width: 0px) and (max-width: 767px) {
	.purchase__wrap:nth-child(1) {
		flex-direction: column;
		text-align: center;
		margin-top: 0;
	}

	.purchase__head {
		flex-direction: column;
	}

	.purchase__head {
		padding-top: 111px;
	}

	.purchase__rotate {
		margin-top: 23px;

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	.purchase__content {
		margin-top: 30px;
		flex-direction: column;
	}

	.purchase__wrap {
		width: 100%;
	}

	.purchase__contact {
		align-self: center;
		margin-top: 0;

		& span {
			text-align: center;
		}

		& a {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.purchase__info-image {
		margin-top: 40px;
	}

	.purchase__wrap {
		margin-top: 111px;
	}

	.purchase__text {
		margin-top: 30px;
	}
}