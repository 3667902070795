body {
  min-width: 375px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: "GothamPro", "Arial", sans-serif;
  height: calc(100vh - calc(100vh - 100%));
  color: #111111;
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

section {
  margin: 0;
}

h2,
h3 {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  position: relative;
  z-index: 17;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  width: 100%;
  max-width: 1366px;
  min-width: 375px;
  padding: 0 100px;
  margin: 0 auto;
  box-sizing: border-box;
}

.title {
  font-family: inherit;
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
  color: #13110c;
}

.line-wrap,
.line-wrap span {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: -40px -5px -15px;
  padding: 40px 5px 15px;
  overflow: hidden;
}

@media (min-width: 0px) and (max-width: 1365px) {
  .container {
    padding: 0 30px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .container {
    padding: 0 20px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }
}

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.col:first-of-type {
  margin-left: 0;
}

.row .three {
  padding: 80px 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger.is-active {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger.is-active .line:nth-child(2) {
  width: 0px;
}

#hamburger.is-active .line:nth-child(1),
#hamburger.is-active .line:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-13px) rotate(90deg);
}

$btn-width: 170px !default;
$btn-height: 48px !default;
$btn-full: $btn-height + $btn-width !default;
$btn-half: $btn-full/2 !default;
$bg-color: #eeeeee !default;

.btn-6 {
  $btn-color: #3e7bbf;
  $btn-color-dark: shade($btn-color, 40%);
  color: tint($btn-color);

  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  width: max-content;
  margin: 1rem auto;
  border: 1px solid #3e7bbf;
  font-weight: 500;

  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  // height: 100%;
  min-height: 47px;

  color: #ffffff;
  z-index: 1;
  border-radius: 123px;
  background-color: #3e7bbf;
  margin: 0;
  text-align: center;

  padding: 17px 33px;

  span {
    @include absolute();
    display: block;
    @include size(0);
    border-radius: 50%;
    background-color: #fff;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    color: #3e7bbf;
    // background: #fff;
    // border-color: #fff;

    & svg path {
      stroke: #3e7bbf;
    }

    span {
      @include size(225%, $btn-width * 2.25);
    }
  }

  &:active {
    background-color: $btn-color;
  }

  svg {
    margin-left: 15px;
  }
}

@keyframes updown {
  0% {
    transform: translateY(-3%);
  }

  50% {
    transform: translateY(1%);
  }

  100% {
    transform: translateY(-3%);
  }
}

.updown {
  animation: updown 5s ease infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 6s linear infinite;
}

[data-aos^="fade"] {
  transition-timing-function: cubic-bezier(0.25, 0.74, 0.22, 0.99);
}

.ymaps-2-1-79-inner-panes {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.omron__video-view {
  position: relative;
  padding-top: 100px;
  z-index: 1;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    z-index: 2;
  }

  & video {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 1365px) {
  .omron__video-view {
    margin-top: 0;
    padding: 0;
  }

  .omron__video-view::after {
    display: none;
  }
}

@media (min-width: 1366px) {
  #anim-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  #app {
    position: absolute;
    width: 100%;
    left: 0;
    will-change: transform;
  }

  #app.app::after {
    display: none;
  }
}
