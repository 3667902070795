.partners__swiper {
	width: 100vw;
	overflow: hidden;
}

.partners {
	padding-top: 90px;
	padding-bottom: 65px;
	border-top: 1px solid #CECECE;
	border-bottom: 1px solid #CECECE;
	overflow: hidden;
	margin-top: 35px;
}

.partners__slide-wrapper {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	& img {
		max-width: 211px;
		width: 100%;
		object-fit: cover;
	}

	& a {
		filter: grayscale(100%);
		transition: 0.3s ease;

		&:hover {
			filter: grayscale(0%);
		}
	}
}

.partners__slide {
	height: auto;
}

.partners__pagination {
	position: relative;
	margin-top: 65px;

	& .swiper-pagination-bullet {
		background: #C4C4C4;
		opacity: 1;
		width: 5px;
		height: 5px;
		margin: 0 10px !important;
	}

	& .swiper-pagination-bullet-active {
		background: #61B4E1;
	}
}

@media(min-width:0px) and (max-width: 1365px) {
	.partners__pagination {
		margin-top: 55px;
	}

	.partners {
		padding-top: 55px;
		padding-bottom: 60px;
	}
}

@media(min-width:0px) and (max-width: 767px) {
	.partners {
		margin-top: 94px;
		padding-top: 108px;
	}

	.partners__pagination {
		margin-top: 21px;
	}
}