.advantage {
  position: relative;
  overflow: hidden;
  margin-top: -80px;
  padding-top: 80px;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    top: -80px;
    width: 1px;
    height: calc(100% + 75px);
    background-color: #cecece;
    transform: translateX(-50%);
  }
}

.advantage__title {
  max-width: 320px;
  margin-top: 98px;
}

.advantage__wrapper {
  padding-top: 81px;
}

.advantage__item-num {
  font-family: inherit;
  font-weight: bold;
  font-size: 42px;
  line-height: 42px;
  color: #e3e3e3;
  align-self: flex-end;
}

.advantage__item-title {
  font-family: inherit;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #13110c;
  max-width: 390px;
}

.advantage__item-text {
  font-family: inherit;
  font-size: 15px;
  line-height: 24px;
  color: #898989;
  margin-top: 23px;
  max-width: 390px;
}

.advantage__slide-title {
  font-family: inherit;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #13110c;
  margin-top: 65px;
  max-width: 400px;
}

.advantage__slide-text {
  font-family: inherit;
  font-size: 15px;
  line-height: 24px;
  color: #13110c;
  margin-top: 31px;
  max-width: 400px;
}

.advantage__slide-icon {
  width: 64px;
  height: 58px;
  object-fit: contain;
}

.advantage__wrapper {
  display: flex;
}

.advantage__wrap {
  width: 50%;
  position: relative;

  &:nth-child(1) {
    padding-right: 115px;
    z-index: 3;
    background-color: #fff;

    &::after {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: -100vw;
      z-index: 3;
    }
  }

  &:nth-child(2) {
    z-index: 2;
    padding-top: 100px;
  }
}

.advantage__item {
  margin-top: 100px;
  display: flex;
}

.advantage__item-content {
  margin-left: 33px;
}

.advantage__decor {
  position: relative;
  width: 367px;
  height: 470px;
  background: linear-gradient(186.48deg, #838b8e 5.4%, #697073 94.9%);
  border-radius: 456px 456px 40px 40px;
  margin-left: 158px;
  margin-top: -50px;

  &::after {
    content: "";
    position: absolute;
    width: 295px;
    height: 397px;
    background-image: url("../img/advantage-border.png");
    background-repeat: no-repeat;
    background-position: center center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: none;
  }

  & div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: max-content;
    height: max-content;

    & img {
      width: 284px;
      height: 451px;
      object-fit: cover;
    }
  }
}

.advantage__swiper {
  width: 684px;
  margin-top: 100px;
}

.advantage__slide {
  max-width: 50vw;
  height: auto;
  padding: 95px 86px;
  box-sizing: border-box;

  &:nth-child(odd) {
    background: #eaeaea;
  }

  &:nth-child(even) {
    background: #f6f6f6;
  }
}

.advantage__swiper-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #181818;
  height: 138px;
  padding: 50px 86px;
  width: 50vw;
}

.advantage__pagination {
  position: relative;

  & .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
    width: 5px;
    height: 5px;
    margin: 0 10px !important;
  }

  & .swiper-pagination-bullet-active {
    background: #61b4e1;
  }
}

.advantage_swiper-bar-wrap {
  position: relative;
  display: flex;
  align-self: center;

  & .advantage__swiper-next,
  .advantage__swiper-prev {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    background: transparent;

    margin: 0 15px;

    &::after {
      display: none;
    }

    &::before {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;

      width: 48px;
      height: 48px;

      border-radius: 50%;
      transition: 0.3s ease;
      border-radius: 50%;
      background: #3e7bbf;
    }

    &:hover {
      border-color: transparent;

      &::before {
        background: #3e7bbf;
        transform: scale(1.2);
      }

      & svg path {
        stroke: #fff;
      }
    }

    & svg {
      z-index: 1;

      & path {
        stroke: #fff;
      }
    }

    &.swiper-button-disabled {
      background: transparent;

      & svg path {
        stroke: #6e6c6a;
      }

      &::before {
        background: transparent;
      }
    }
  }

  .advantage__swiper-bar-wrap {
    &:nth-child(1) {
      display: flex;
      align-self: center;
    }

    &:nth-child(2) {
      display: flex;
      align-self: center;
      margin-top: -5px;
    }
  }
}

.advantage__omron-image {
  width: 100vw;
  position: relative;
  z-index: 1;
}

@media (min-width: 0px) and (max-width: 1365px) {
  .advantage {
    margin-top: 0;
    padding-top: 0;
  }

  .advantage__wrapper {
    flex-direction: column;
  }

  .advantage__wrap:nth-child(1) {
    position: relative;
    padding-right: 0;
    width: 100%;
    padding-bottom: 115px;

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 300vw;
      background: #cecece;
      left: -100vw;
      bottom: 0;
    }
  }

  .advantage__title {
    max-width: 100%;
    text-align: center;
  }

  .advantage__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .advantage__item {
    width: 48%;

    &:nth-child(odd) {
      margin-right: 1%;
    }

    &:nth-child(even) {
      margin-left: 1%;
    }
  }

  .advantage__item-num {
    align-self: flex-start;
  }

  .advantage__title {
    margin-top: 20px;
  }

  .advantage__wrap:nth-child(1)::after {
    display: none;
  }

  .advantage::after {
    display: none;
  }

  .advantage__decor {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .advantage__wrap:nth-child(2) {
    width: 100%;
    padding-top: 123px;
  }

  .advantage__swiper {
    width: 100vw;
    margin-left: -30px;
  }

  .advantage__slide {
    max-width: 100%;
  }

  .advantage__swiper-bar {
    width: 100vw;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .advantage__wrapper {
    padding-top: 50px;
  }

  .advantage__list {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
  }

  .advantage__title {
    max-width: 280px;
    text-align: left;
  }

  .advantage__item-title {
    margin-top: 15px;
  }

  .advantage__item-text {
    max-width: 100%;
  }

  .advantage__item {
    flex-direction: column;
    width: 100%;
  }

  .advantage__item-content {
    margin-left: 0;
  }

  .advantage__item-title {
    max-width: 100%;
  }

  .advantage__item-num {
    align-self: flex-start;
  }

  .advantage__item {
    margin-top: 30px;

    &:nth-child(odd) {
      margin-right: 0;
    }

    &:nth-child(even) {
      margin-left: 0;
    }
  }

  .advantage__slide {
    padding: 95px 35px 78px 60px;
  }

  .advantage__decor {
    width: 238px;
    height: 306px;

    &::after {
      width: 270px;
      height: 255px;
      background-size: contain;
    }

    & div img {
      width: 187px;
      height: 293px;
    }
  }

  .advantage__swiper {
    margin-top: 112px;
  }

  .advantage__omron-image {
    max-height: 100%;
    height: 510px;
    object-fit: cover;
  }

  .advantage__swiper-bar {
    margin-left: 10px;
    padding: 50px 67px;
  }

  .advantage__wrap:nth-child(1) {
    padding-bottom: 60px;
  }

  .advantage__wrap:nth-child(2) {
    padding-top: 60px;
  }

  .advantage__swiper {
    margin-top: 60px;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
  .advantage__swiper-bar {
    flex-direction: column;
    justify-content: center;
    padding: 50px 67px;
    padding-top: 30px;
    min-height: 185px;
    height: 170px;
  }
}
