.style {
  padding-top: 195px;
  position: relative;
  z-index: 11;
  background-color: #fff;
}

.style__subtitle {
  font-family: inherit;
  font-size: 15px;
  line-height: 24px;
  color: #575756;
  max-width: 530px;
  margin-left: 90px;
}

.style__titles {
  display: flex;
}

.style__title {
  max-width: 480px;
}

.style__slide-content {
  & div {
    & p:nth-child(1) {
      font-family: inherit;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #13110c;
    }

    & p:nth-child(2) {
      font-family: inherit;
      font-size: 15px;
      line-height: 24px;
      color: #898989;
      margin-top: 20px;
    }

    &:nth-child(2) {
      margin-top: 78px;
    }
  }
}

.style__swiper {
  width: calc(50vw - 100px);
  max-width: 480px;
  overflow: hidden;
}

.style__content {
  display: flex;
  margin-top: 93px;
}

.style__swiper-wrapper {
  width: calc(50vw - 100px);
  max-width: 480px;
}

.style__images {
  width: calc(50vw - 100px);
  display: flex;
  margin-left: 90px;
  align-items: flex-start;

  & .simpleParallax {
    &:nth-child(1) {
      margin-right: 62px;
    }
  }

  & img {
    &:nth-child(1) {
      max-width: 337px;
      object-fit: cover;
      align-self: flex-start;
    }

    &:nth-child(2) {
      max-width: 199px;
      object-fit: cover;
      align-self: flex-start;
    }
  }
}

.style__swiper-bar {
  position: relative;
  height: 48px;
  display: flex;
  align-self: center;
  margin-top: 60px;

  & .style__swiper-next,
  .style__swiper-prev {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    background: transparent;

    margin: 0 15px;

    &::after {
      display: none;
    }

    &::before {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;

      width: 48px;
      height: 48px;

      border-radius: 50%;
      transition: 0.3s ease;
      border-radius: 50%;
      background: #3e7bbf;
    }

    &:hover {
      border-color: transparent;

      &::before {
        background: #3e7bbf;
        transform: scale(1.2);
      }

      & svg path {
        stroke: #fff;
      }
    }

    & svg {
      z-index: 1;

      & path {
        stroke: #fff;
      }
    }

    &.swiper-button-disabled {
      background: transparent;

      & svg path {
        stroke: #6e6c6a;
      }

      &::before {
        background: transparent;
      }
    }
  }

  .style__swiper-bar-wrap {
    &:nth-child(1) {
      display: flex;
      align-self: center;
    }

    &:nth-child(2) {
      display: flex;
      align-self: center;
      margin-top: -5px;
    }
  }
}

.style__swiper-pagination {
  position: static;
  height: 2px;
  width: 133px;
  margin: 0 28px;
  align-self: center;

  & .swiper-pagination-progressbar-fill {
    background: #000000;
  }
}

.style__swiper-bar-active {
  font-family: inherit;
  font-size: 20px;
  line-height: 24px;
  color: #3e7bbf;
  display: inline-block;
  min-width: 20px;
  text-align: center;
}

.style__swiper-bar-all {
  font-family: inherit;
  font-size: 20px;
  line-height: 24px;
  color: #13110c;
  display: inline-block;
  min-width: 20px;
  text-align: center;
}

@media (min-width: 1366px) {
  .style {
    padding-bottom: 180px;
  }
}

@media (min-width: 0px) and (max-width: 1365px) {
  .style {
    padding-top: 118px;
    overflow-x: hidden;
  }

  .style__titles {
    flex-direction: column;
  }

  .style__subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 710px;
    margin-top: 90px;
  }

  .style__title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .style__content {
    flex-direction: column;
  }

  .style__swiper-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .style__swiper {
    width: 100%;
    max-width: 581px;
    margin-left: auto;
    margin-right: auto;
  }

  .style__slide-content {
    text-align: center;
  }

  .style__swiper-bar {
    justify-content: center;
  }

  .style__swiper-bar .style__swiper-bar-wrap:nth-child(2) {
    margin-left: 30px;
  }

  .style__images {
    width: 100%;
    margin-left: 0;
    justify-content: center;
    margin-top: 128px;
  }

  .style__images img:nth-child(1) {
    max-width: 462px;
  }

  .style__images img:nth-child(2) {
    max-width: 216px;
    margin-left: 30px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .style__title {
    margin-top: 0px;
  }

  .style__subtitle {
    font-size: 15px;
    line-height: 24px;
    margin-top: 80px;
    max-width: 100%;
  }

  .style__titles {
    margin-top: 0px;
  }

  .style__swiper {
    max-width: 100%;
  }

  .style__images {
    flex-direction: column;
    margin-top: 115px;
    align-items: center;
  }

  .style__images img:nth-child(1) {
    max-width: 334px;
    margin-left: auto;
    margin-right: auto;
  }

  .style__images img:nth-child(2) {
    max-width: 213px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .style__swiper-bar {
    flex-direction: column;
    margin-top: 111px;
  }

  .style__swiper-bar .style__swiper-bar-wrap:nth-child(2) {
    margin-left: 0;
  }

  .style__images .simpleParallax:nth-child(1) {
    margin-right: 0;
  }

  .style__images .simpleParallax:nth-child(2) {
    margin-top: 30px;
  }
}
