.footer {
	background: linear-gradient(90deg, rgba(24, 24, 24, 1) 50%, rgba(31, 31, 31, 1) 50%);
	overflow-x: hidden;
}

.footer__wrap-main {
	background: linear-gradient(90deg, rgba(24, 24, 24, 1) 40%, rgba(31, 31, 31, 1) 40%);
	border-right: 1px solid #444444;
}

.footer__logo-list {
	display: flex;
	align-items: center;
	margin-left: 70px;

	& li {
		display: flex;
		align-items: center;
		margin-right: 10px;

		& span {
			font-family: inherit;
			font-size: 13px;
			line-height: 16px;
			color: #FFFFFF;
			height: max-content;
			align-self: flex-start;
			margin-left: 5px;
			margin-top: -5px;
		}
	}

	&--doble {
		display: none;
	}
}


.footer__nav {

	width: 60%;

	& ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-left: auto;
		width: 100%;
		justify-content: flex-end;
		padding-right: 80px;

		& li {
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}

			& span {
				display: none;
			}

			& a {
				font-family: inherit;
				font-size: 15px;
				line-height: 62px;
				color: #898989;
				transition: 0.3s ease;

				&:hover {
					color: #fff;
				}
			}
		}
	}
}

.footer__logos {
	display: flex;
	align-items: center;
	width: 40%;
}

.footer__wrap {
	&:nth-child(1) {
		display: flex;
		align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
	}

	&:nth-child(2) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 38px;
		padding-top: 38px;
		position: relative;

		&::after {
			position: absolute;
			content: '';
			background-color: #444444;
			width: 300vw;
			height: 1px;
			top: 0;
			left: -100vw;
		}

		& p {
			font-family: inherit;
			font-size: 15px;
			line-height: 28px;
			color: #898989;
		}

		& a {
			color: #fff;
			margin-right: 80px;
		}
	}
}

@media(min-width:0px) and (max-width: 1365px) {
	.footer__wrap:nth-child(2) a {
		margin-right: 30px;
	}

	.footer__nav ul {
		padding-right: 30px;
	}
}

@media(min-width:0px) and (max-width: 1919px) {
	.footer__logo-list {
		display: none;

		&--doble {
			display: flex;
			margin-left: auto;
			margin-right: auto;
			margin-top: 44px;
			width: 100%;
			justify-content: center;
		}
	}

	.footer__wrap:nth-child(1) {
		flex-wrap: wrap;
		background: #181818;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			background: #181818;
			height: 100%;
			width: 100vw;
			top: 0;
			left: -100vw;
		}

		&::before {
			content: '';
			position: absolute;
			background: #181818;
			height: 100%;
			width: 100vw;
			top: 0;
			right: -100vw;
		}
	}

	.footer__wrap:nth-child(2) {
		background: #1F1F1F;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			background: #1F1F1F;
			height: 100%;
			width: 100vw;
			top: 0;
			left: -100vw;
		}

		&::before {
			content: '';
			position: absolute;
			background: #1F1F1F;
			height: 100%;
			width: 100vw;
			top: 0;
			right: -100vw;
		}
	}

	.footer {
		background: none;
	}

	.footer__wrap-main {
		border-right: none;
		background: none;
	}

	.footer__logos {
		width: auto;
		margin-right: 30px;
	}

	.footer__nav {
		width: auto;
		margin-left: auto;
	}
}

@media(min-width:0px) and (max-width: 767px) {
	.footer__wrap:nth-child(1) {
		flex-direction: column;
		padding: 111px 0;
	}

	.footer__nav {
		margin-left: 0;

		& ul {
			padding-right: 0;
			flex-direction: column;

			& li {
				margin-right: 0;
			}
		}
	}

	.footer__logos {
		margin-right: 0;
	}

	.footer__nav {
		margin-top: 40px;
	}

	.footer__wrap:nth-child(2) a {
		margin-right: 0;
	}
}

@media(min-width:0px) and (max-width: 500px) {
	.footer__wrap:nth-child(2) {
		flex-direction: column;
		padding: 40px 0;

		& p:nth-child(2) {
			margin-top: 20px;
		}
	}
}