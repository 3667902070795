.evolv {
  padding: 180px 0;
  padding-top: 300px;
  overflow: hidden;
  position: relative;
  z-index: 11;
  background-color: #fff;
}

.evolv__title {
  max-width: 560px;
  margin-bottom: 60px;
  margin-left: auto;
  z-index: 1;
  position: relative;
}

.evolv__subtitle {
  font-family: inherit;
  font-size: 15px;
  line-height: 24px;
  color: #13110c;
  margin-top: 20px;
  max-width: 560px;
  margin-left: auto;
  z-index: 1;
  position: relative;
}

.evolv__ref {
  width: 50%;

  & div {
    max-width: 462px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 100px;
  }

  & p {
    font-family: inherit;
    font-size: 15px;
    line-height: 22px;
    color: #898989;
    margin-top: 25px;
  }

  & a {
    display: flex;
    align-items: center;
    width: max-content;
    margin-top: 60px;

    & span {
      position: relative;
      font-family: inherit;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #3e7bbf;

      &::after {
        position: absolute;
        left: 0;
        bottom: 0px;
        content: "";
        width: 100%;
        height: 2px;
        border-bottom: 2px solid #3e7bbf;
        will-change: transform, color;
        transform: scaleX(1);
        transform-origin: 0 50%;
        transition: transform 0.35s;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }

    & svg {
      margin-left: 32px;
    }

    &:hover {
      & span {
        &::after {
          transform: scaleX(0);
          transform-origin: 100% 50%;
          transition-timing-function: ease;
        }
      }
    }
  }
}

.evolv__wrap {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 935px;
  width: 100%;
  position: relative;
  padding-top: 168px;
  padding-bottom: 213px;
  padding-right: 40px;

  &::after {
    position: absolute;
    content: "";
    background: #f6f6f6;
    width: 100%;
    max-width: 1038px;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.evolv__image {
  position: absolute;
  top: 5px;
  left: 0;
  transform: translateY(-100%);
}

.evolv__decor {
  position: absolute;
  top: 0;
  right: 0;
  background: #f6f6f6;
  width: 100vw;
  height: 100%;
  transform: translateX(100%);
}

.evolv__360 {
  position: relative;
  height: max-content;
  margin-top: -180px;

  & img {
    &:nth-child(1) {
      display: block;
      max-width: 600px;
      object-fit: cover;
    }
  }

  & .evolv__rotate {
    margin-top: -180px;

    pointer-events: none;
    position: absolute;
    bottom: 1px;
    left: 1px;
    transform: translate(-50%, 50%);
  }
}

.evolv__cuff {
  margin-top: 90px;
  display: flex;
}

.evolv__omron {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translate(-50%, -50%);

  background: linear-gradient(186.48deg, #838b8e 5.4%, #697073 94.9%);
  border-radius: 456px;

  width: 357px;
  height: 565px;

  & div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  & img {
    max-width: 471px;
    object-fit: contain;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: "";

    width: 295px;
    height: 503px;

    background-image: url("../img/evolv-border.png");
    background-repeat: no-repeat;
    background-position: center center;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    position: absolute;
    content: "";

    position: absolute;
    content: "";
    filter: blur(60px);
    transform: rotate(98.34deg);
    width: 85px;
    height: 110px;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000000;
    z-index: 1;
  }
}

@media (min-width: 0px) and (max-width: 1365px) {
  .evolv__omron {
    position: relative;
    transform: none;
    order: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .evolv__title {
    order: 2;
  }

  .evolv__subtitle {
    order: 3;
  }

  .evolv {
    margin-top: 40px;
    padding-bottom: 120px;
  }

  .evolv__content {
    padding-left: 40px;
  }

  .evolv__wrap {
    padding-top: 90px;
  }

  .evolv__title {
    margin-left: 54px;
    margin-top: 80px;
  }

  .evolv__subtitle {
    margin-left: 54px;
    max-width: 100%;
  }

  .evolv__cuff {
    flex-direction: column;
    margin-left: auto;
    max-width: 935px;
  }

  .evolv__360 {
    order: 1;
    margin-right: auto;
    margin-left: auto;
  }

  .evolv__360 .evolv__360-img {
    max-width: 560px !important;
  }

  .evolv__ref {
    order: 2;
    width: 100%;
    margin-top: 55px;

    & div {
      max-width: 400px;
      padding-right: 0;

      padding-left: 40px;
    }
  }

  .evolv__rotate {
    & img {
      margin-top: 0;
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .evolv__content {
    padding-left: 0;
  }

  .evolv__decor {
    right: unset;
    left: -20px;
    transform: none;
  }

  .evolv__wrap {
    padding-right: 0;
    padding-top: 70px;
    padding-bottom: 150px;
  }

  .evolv__title {
    margin-left: 0;
  }

  .evolv__subtitle {
    margin-left: 0;
  }

  .evolv {
    padding: 130px 0;
    padding-bottom: 110px;
  }

  .evolv__image {
    left: -20px;
  }

  .evolv__omron {
    width: 263px;
    height: 380px;

    & img {
      max-width: 315px;
    }

    &::after {
      width: 215px;
      height: 330px;
      background-size: contain;
    }
  }

  .evolv__title {
    margin-top: 58px;
    margin-bottom: 26px;
  }

  .evolv__360-img {
    width: 100%;
    height: 304px;
  }

  .evolv__360 .evolv__rotate {
    left: 50%;
  }

  .evolv__ref {
    margin-top: 104px;

    & div {
      padding-left: 0;
    }
  }

  .evolv__360 .evolv__rotate {
    display: none;
  }
}
